import { Box, Flex, Heading, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import AdBanner from 'ui/shared/ad/AdBanner';
import ProfileMenuDesktop from 'ui/snippets/profileMenu/ProfileMenuDesktop';
import SearchBar from 'ui/snippets/searchBar/SearchBar';
import WalletMenuDesktop from 'ui/snippets/walletMenu/WalletMenuDesktop';

const BACKGROUND_DEFAULT =
  'radial-gradient(103.03% 103.03% at 0% 0%, rgba(183, 148, 244, 0.8) 0%, rgba(0, 163, 196, 0.8) 100%), var(--chakra-colors-blue-400)';
const TEXT_COLOR_DEFAULT = 'white';
const BORDER_DEFAULT = 'none';

const HeroBanner = () => {
  const background = useColorModeValue(
    config.UI.homepage.heroBanner?.background?.[0] ||
      config.UI.homepage.plate.background ||
      BACKGROUND_DEFAULT,
    config.UI.homepage.heroBanner?.background?.[1] ||
      config.UI.homepage.plate.background ||
      BACKGROUND_DEFAULT,
  );

  const textColor = useColorModeValue(
    config.UI.homepage.heroBanner?.text_color?.[0] ||
      config.UI.homepage.plate.textColor ||
      TEXT_COLOR_DEFAULT,
    config.UI.homepage.heroBanner?.text_color?.[1] ||
      config.UI.homepage.plate.textColor ||
      TEXT_COLOR_DEFAULT,
  );

  const border = useColorModeValue(
    config.UI.homepage.heroBanner?.border?.[0] || BORDER_DEFAULT,
    config.UI.homepage.heroBanner?.border?.[1] || BORDER_DEFAULT,
  );

  return (
    <Flex
      w="100%"
      background={ background }
      border={ border }
      borderRadius="md"
      p={{ base: 4, lg: 8 }}
      columnGap={ 8 }
      alignItems="center"
    >
      <Box flexGrow={ 1 }>
        <Flex
          mb={{ base: 2, lg: 3 }}
          justifyContent="space-between"
          alignItems="center"
          columnGap={ 2 }
        >
          <Heading
            as="h1"
            fontSize={{ base: '18px', lg: '30px' }}
            lineHeight={{ base: '24px', lg: '36px' }}
            fontWeight={{ base: 500, lg: 700 }}
            color={ textColor }
          >
            { config.meta.seo.enhancedDataEnabled ?
              `${ config.chain.name } Blockchain Explorer` :
              `${ config.chain.name } Explorer` }
          </Heading>
          { config.UI.navigation.layout === 'vertical' && (
            <Box display={{ base: 'none', lg: 'flex' }}>
              { config.features.account.isEnabled && (
                <ProfileMenuDesktop isHomePage/>
              ) }
              { config.features.blockchainInteraction.isEnabled && (
                <WalletMenuDesktop isHomePage/>
              ) }
            </Box>
          ) }
        </Flex>
        <SearchBar isHomepage/>
      </Box>
      <AdBanner
        platform="mobile"
        w="fit-content"
        flexShrink={ 0 }
        borderRadius="md"
        overflow="hidden"
        display={{ base: 'none', lg: 'block ' }}
      />
    </Flex>
  );
};

export default React.memo(HeroBanner);
